import React, { useContext, useState, useEffect } from 'react';
import { Box, Text, List, Button, Pagination, Grid } from 'grommet';
import { Trash } from 'grommet-icons';
import { WasteContext } from '../WasteContext';

const WasteList = () => {
  const { wasteData, removeWaste, currentWasteItem, changeCurrentWasteItem, wasteAmount } = useContext(WasteContext);

  const handleChange = ({ page }) => {
    changeCurrentWasteItem(page);
  }

  useEffect(() => {
    changeCurrentWasteItem(1)
  }, [])

  return (
    <Box pad="medium">
      {wasteData.length > 0 && <React.Fragment><Text size="xlarge">Abfallliste</Text><Box margin={{ vertical: 'small' }} /></React.Fragment>}
      {currentWasteItem && <List
        data={[].concat(currentWasteItem)}
        primaryKey={(datum) => 
          <Box direction="row" align="stretch">
            <Text>{new Date(datum.date).toLocaleString()}</Text>
            <Box margin={{ horizontal: 'small' }} />
            <Text>{datum.quantity} {datum.unit}</Text>
            <Box margin={{ horizontal: 'small' }} />
            <Text>{datum.subsubtype}</Text>
            <Box margin={{ horizontal: 'small' }} />
            <Box>
              <Text size="xsmall">{datum.subtype}</Text>
              <Text size="xsmall">{datum.type}</Text>
            </Box>
          </Box>}
/*         secondaryKey={(datum) => `${datum.quantity} ${datum.unit}`} */
        itemKey="id"
        action={(item, index) => (
          <Button icon={<Trash />} onClick={() => removeWaste(item.id)} key={item.id} />
        )}
      />}
      <Pagination numberItems={wasteData.length} step={1} onChange={handleChange} />
    </Box>
  );
};

export default WasteList;

//<React.Fragment><Text>{datum.subsubtype}</Text><Text size="xsmall">{datum.subtype}</Text><Text size="xsmall">{datum.type}</Text></React.Fragment>