import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Form, FormField, TextInput, Select, Text } from 'grommet';
import { WasteContext } from '../WasteContext';
import wasteDataJson from '../wasteCategories.json';

const WasteForm = () => {
  const [type, setType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('kg');
  const { addWaste } = useContext(WasteContext);
  const [wasteType, setWasteType] = useState(wasteDataJson[0].type);
  const [subtype, setSubtype] = useState(wasteDataJson[0].subtypes[0].type);
  const [subsubtype, setSubsubtype] = useState(wasteDataJson[0].subtypes[0].subtypes[0].type);
  const [isValidQuantity, setIsValidQuantity] = useState(true);

  const onSubmit = (event) => {
    event.preventDefault();
    addWaste(wasteType, subtype, subsubtype, quantity, unit);
    setType('');
    setQuantity('');
    setUnit('kg');
  };

  useEffect(() => {
    setWasteType(wasteDataJson[0].type);
    setSubtype(wasteDataJson[0].subtypes[0].type);
    setSubsubtype(wasteDataJson[0].subtypes[0].subtypes[0].type);
  }, []);

  const handleSubtypeChange = ({ option }) => {
    setSubtype(option);
    const selectedSubtype = wasteDataJson.find(waste => waste.type === wasteType).subtypes.find(sub => sub.type === option);
    if (selectedSubtype.subtypes && selectedSubtype.subtypes.length > 0) {
      setSubsubtype(selectedSubtype.subtypes[0].type);
    } else {
      setSubsubtype('');
    }
  };

  const handleQuantityChange = (event) => {
    const quantity = event.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(quantity)) {
      setIsValidQuantity(true);
      setQuantity(quantity);
    } else {
      setIsValidQuantity(false);
    }
  };

  const validateQuantity = (quantity) => {
    const regex = /^\d*\.?\d*$/;
    if (!regex.test(quantity)) {
      return 'Bitte geben Sie nur Zahlen ein';
    }
  };

  const currentSubtypes = wasteDataJson.find(waste => waste.type === wasteType).subtypes.map(subtype => subtype.type);
  const currentSubsubtypes = wasteDataJson.find(waste => waste.type === wasteType).subtypes.find(sub => sub.type === subtype).subtypes.map(subsub => subsub.type);

  const validateForm = event => {
  }

  return (
    <Box pad="medium">
      <Text size="xlarge">Abfall hinzufügen</Text>
      <Box margin={{ vertical: 'small' }} />
      <Form onSubmit={onSubmit} validate="submit" onValidate={validateForm}>
        <FormField name="type" label="Abfallart">
          <Select
            options={[wasteType]}
            value={wasteType}
            onChange={({ option }) => setWasteType(option)}
          />
        </FormField>
        <FormField name="subtype" label="Kategorie">
          <Select
            options={currentSubtypes}
            value={subtype}
            onChange={handleSubtypeChange}
          />
        </FormField>
        <FormField name="subsubtype" label="Unterkategorie">
          <Select
            options={currentSubsubtypes}
            value={subsubtype}
            onChange={({ option }) => setSubsubtype(option)}
          />
        </FormField>
        <FormField name="quantity" label="Menge">
          <TextInput
            placeholder="Menge eingeben"
            value={quantity}
            onChange={handleQuantityChange}
            required
          />
        </FormField>
        <FormField name="unit" label="Einheit">
          <Select
            options={['kg']}
            value={unit}
            onChange={({ option }) => setUnit(option)}
          />
        </FormField>
        <Box margin={{ vertical: 'small' }} />
        <Box direction="row" gap="medium">
          <Button type="submit" primary label="Add" />
        </Box>
      </Form>
    </Box>
  );
};

export default WasteForm;
