import React, { createContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const WasteContext = createContext();

export const WasteProvider = (props) => {
  const [wasteData, setWasteData] = useState([]);
  const [currentWasteItem, setCurrentWasteItem] = useState({})

  const addWaste = (wasteType, subtype, subsubtype, quantity, unit) => {
    setWasteData([...wasteData, { id: uuidv4(), type: wasteType, subtype: subtype, subsubtype: subsubtype, quantity: (quantity !== "" ? quantity : "0"), unit, date: Date.now() }]);
  };

  const removeWaste = (id) => {
    setWasteData(current => current.filter(e => e.id !== id));
  };

  const changeCurrentWasteItem = i => {
    setCurrentWasteItem(wasteData ? wasteData.toReversed()[i-1] : {});
  }

  const wasteAmount = () => {
    return wasteData.reduce((acc, cv) => {
      return acc + parseInt(cv.quantity)
    }, 0)
  }

  useEffect(() => {
    changeCurrentWasteItem({})
  }, [])

  useEffect(() => {
    changeCurrentWasteItem(1)
  }, [wasteData])

  return (
    <WasteContext.Provider value={{ wasteData, addWaste, removeWaste, currentWasteItem, changeCurrentWasteItem, wasteAmount }}>
      {props.children}
    </WasteContext.Provider>
  );
};
