import React, { useContext, useState } from 'react';
import { Grommet, Header, Box, Anchor, Text, Button, Layer, List, Grid, InfiniteScroll } from 'grommet';
import WasteForm from './components/WasteForm';
import WasteList from './components/WasteList';
import { FaCircleNotch } from 'react-icons/fa';
import WasteDataChart from './components/WasteDataChart';
import { WasteContext } from './WasteContext';
import { Trash, Close } from 'grommet-icons';


const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const AppBar = (props) => (
  <Header background="brand" pad="small" height="xsmall" {...props} />
);


const MyItem = ({ item }) => {
  return (
    <Box pad="medium" border={{ side: 'bottom' }} align="center">
      <Text>{item.id}</Text>
    </Box>
  )
}


function App() {
  const [showReport, setShowReport] = useState(false);

  const { wasteData, removeWaste } = useContext(WasteContext);


  const handleOpenReportClick = () => {
    setShowReport(true)
  }

  const handleCloseReportClick = () => {
    setShowReport(false)
  }

  const allItems = Array(2000)
  .fill()
  .map((_, i) => `item ${i + 1}`);


  const InfiniteScrollItem = ({ item }) => (
    <Box pad="medium" direction="row" align="center">
      <Text>{new Date(item.date).toLocaleString()}</Text>
      <Box margin={{ horizontal: 'small' }} />
      <Text>{item.quantity} {item.unit}</Text>
      <Box margin={{ horizontal: 'small' }} />
      <Text>{item.subsubtype}</Text>
      <Box margin={{ horizontal: 'small' }} />
      <Box>
        <Text size="xsmall">{item.subtype}</Text>
        <Text size="xsmall">{item.type}</Text>
      </Box>
      <Button icon={<Trash />} onClick={() => removeWaste(item.id)} key={item.id} />
    </Box>
  );

  return (
    <Grommet theme={theme}>
      <AppBar>
      <Box direction="row" align="center" justify="between" fill="horizontal">
          <FaCircleNotch size={24} color="white" />
          <Text size="large" weight="bold" color="white">WAYST</Text>
          <Box direction="row" gap="medium">
            <Button primary label="PDF Report" onClick={handleOpenReportClick} />
          </Box>
        </Box>
      </AppBar>
      <WasteForm />
      {wasteData.length > 0 && <React.Fragment>
        <WasteList />
      </React.Fragment>}
      {
        showReport && (
          <Layer full>
              <Grid
                fill
                rows={['auto', 'flex']}
                columns={['auto', 'flex']}
                areas={[
                  { name: 'header', start: [0, 0], end: [1, 0] },
                  { name: 'main', start: [1, 1], end: [1, 1] },
                ]}
              >
                <Box
                  gridArea="header"
                  direction="row"
                  align="center"
                  justify="between"
                  pad={{ horizontal: 'medium', vertical: 'small' }}
                  background="light-6"
                >
                  <Button>
                    <Text size="large">Abfallreport</Text>
                  </Button>
                  <Text>WAYST</Text>
                  <Button icon={<Close />} onClick={handleCloseReportClick} />
                </Box>
                <Box gridArea="main" justify="center" align="center">
                  {/* <Text>main</Text> */}
                  <Box width="100%" overflow="auto" pad={{top: "small", bottom: "small"}} >
                    <InfiniteScroll items={wasteData.toReversed()} >
                      {(item) => <InfiniteScrollItem key={item.id} item={item} />}
                    </InfiniteScroll>
                  </Box>
                </Box>
              </Grid>
          </Layer>
        )
      }
    </Grommet>
  );
}

export default App;
